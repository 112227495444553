import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'

import AppBar from '@mui/material/AppBar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Autocomplete from '@mui/material/Autocomplete'
import { Bar } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Moment from 'moment'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

Moment.locale('es')

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const darkTheme = createTheme({})

const TOWN_SELECTION = 1
const ZONE_SELECTION = 2
const ZONE_VIEW = 3

function App() {
  const [step, setStep] = useState(TOWN_SELECTION)
  const [towns, setTowns] = useState([])
  const [zones, setZones] = useState([])
  const [outagesPerDay, setOutagesPerDay] = useState([])
  const [selectedTownId, setSelectedTownId] = useState(null)
  const [selectedTownLabel, setSelectedTownLabel] = useState('')
  const [selectedZoneId, setSelectedZoneId] = useState(null)
  const [selectedZoneLabel, setSelectedZoneLabel] = useState('')

  useEffect(() => {
    if (towns.length === 0) {
      fetch('/towns', {
        method: 'GET'
      }).then(res => res.json()).then(data => {
        setTowns(data.towns)
      })
    }
  })

  const onTownSelect = () => {
    fetch(`/zones/${selectedTownId}`, {
      method: 'GET'
    }).then(res => res.json()).then(data => {
      setZones(data.zones)
    })

    setStep(ZONE_SELECTION)
  }

  const onZoneSelect = () => {
    fetch(`/outages_per_day/${selectedZoneId}`, {
      method: 'GET'
    }).then(res => res.json()).then(data => {
      console.log(data.outages_per_day)
      setOutagesPerDay(data.outages_per_day)
    })

    setStep(ZONE_VIEW)
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div>
        <AppBar component="nav">
          <Toolbar>
            {step !== TOWN_SELECTION && (
              <IconButton aria-label="Regresar" onClick={() => {
                setStep(TOWN_SELECTION)
                setSelectedTownId(null)
                setSelectedTownLabel('')
                setSelectedZoneId(null)
                setSelectedZoneLabel('')
                setZones([])
                setOutagesPerDay([])
              }}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { sm: 'block' } }}
            >
              Apagones de Puerto Rico
            </Typography>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Grid container spacing={1}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {step === TOWN_SELECTION &&
                (<Card sx={{ width: '100%' }}>
                  <CardContent>
                    <Autocomplete
                      disablePortal
                      id="towns"
                      options={towns}
                      loading={towns.length === 0}
                      onChange={(event, value) => {
                        setSelectedTownId(value.id)
                        setSelectedTownLabel(value.label)
                      }}
                      renderInput={(params) => <TextField {...params} label="Pueblo" />}
                    />
                  </CardContent>
                  <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                    <Button size="small" onClick={onTownSelect} disabled={selectedTownId === null}>Seleccionar</Button>
                  </CardActions>
                </Card>)}

              {step === ZONE_SELECTION &&
                (<Card sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography
                      variant="h7"
                      component="div"
                      sx={{ flexGrow: 1, display: { sm: 'block' } }}
                      style={{ paddingBottom: '15px' }}
                    >
                      <b>Pueblo:</b>&nbsp;{selectedTownLabel}
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="zones"
                      options={zones}
                      loading={zones.length === 0}
                      onChange={(event, value) => {
                        setSelectedZoneId(value.id)
                        setSelectedZoneLabel(value.label)
                      }}
                      renderInput={(params) => <TextField {...params} label="Zona(s)" />}
                    />
                  </CardContent>
                  <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                    <Button color="secondary" size="small" onClick={() => {
                      setStep(TOWN_SELECTION)
                      setSelectedTownId(null)
                      setSelectedTownLabel('')
                      setSelectedZoneId(null)
                      setSelectedZoneLabel('')
                      setZones([])
                    }}>Regresar</Button>
                    <Button size="small" onClick={onZoneSelect} disabled={selectedZoneId === null}>Seleccionar</Button>
                  </CardActions>
                </Card>)}

              {step === ZONE_VIEW && (
                <Card sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1, display: { sm: 'block' } }}
                      style={{ paddingBottom: '15px' }}
                    >
                      Apagones por día y % de Tiempo sin Luz
                    </Typography>
                    <Typography
                      variant="h7"
                      component="div"
                      sx={{ flexGrow: 1, display: { sm: 'block' } }}
                      style={{ paddingBottom: '15px' }}
                    >
                      <b>Pueblo:</b>&nbsp;{selectedTownLabel}<br />
                      <b>Zona:</b>&nbsp;{selectedZoneLabel}
                    </Typography>
                    <Bar
                      datasetIdKey='id'
                      options={{
                        responsive: true,
                        plugins: {
                          title: {
                            display: true,
                            text: 'Apagones por día y % de Tiempo sin Luz',
                          },
                        }
                      }}
                      data={{
                        labels: outagesPerDay.map(row => Moment(row.date).format('D MMM YYYY')),
                        datasets: [
                          {
                            id: 1,
                            label: '% de Tiempo sin Luz',
                            data: outagesPerDay.map(row => row.downtime_pcent),
                            backgroundColor: 'rgba(255, 99, 132, 0.5)'
                          }
                        ],
                      }}
                    />
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="Apagones por día y % de Tiempo sin Luz">
                        <TableHead>
                          <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell align="center">% de Tiempo sin Luz</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {outagesPerDay.map((row) => (
                            <TableRow
                              key={row.date}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {Moment(row.date).format('D MMM YYYY')}
                              </TableCell>
                              <TableCell align="center">{(row.downtime_pcent / 100).toLocaleString("en", { style: "percent", minimumFractionDigits: 2 })}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                  <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                    <Button color="secondary" size="small" onClick={() => {
                      setStep(TOWN_SELECTION)
                      setSelectedTownId(null)
                      setSelectedTownLabel('')
                      setSelectedZoneId(null)
                      setSelectedZoneLabel('')
                      setZones([])
                      setOutagesPerDay([])
                    }}>Regresar</Button>
                  </CardActions>
                </Card>
              )}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default App
